import searchIcon from "../../assets/searchIcon.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { setActiveMenu } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../config/server";
import "moment/locale/id";
import loading from "../../assets/loading.gif";
import moment from "moment";
export default function Edukasi() {
  const { request } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(null);
  const [displaySearch, setDisplaySearch] = useState(null);
  const [newsPage, setNewsPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true)
  const prevSearchRef = useRef(search);
  const { state } = useLocation();
  let isTag;
  // console.log(state);
  if (state) {
    isTag = state.isTag;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(isTag, "istegco");

    // console.log(request);
    if (request && isTag) {
      // console.log("gagag");

      getDataByTag(request);
      setSearch(request);
      // setSearch(request);
      // setDisplaySearch(request);
      // getData(request);
    } else if (request) {
      setSearch(request);
      // console.log("2");
      getData(request);
    } else if (search) {
      getData(search);
    } else {
      getData();
    }

    // console.log(request);
    // if (request) {
    //   setSearch(request);
    //   setDisplaySearch(request);
    //   getData(request);
    // } else {
    //   getData();
    // }
    // document.getElementById(request).className +=
    //   " subPathActive";

    if (window.innerWidth > 768) {
      document.getElementById("navBar").style.boxShadow =
        "0px 4px 14px rgb(0 0 0 / 7%)";
    }
  }, [newsPage, request]);

  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const newsPageHandle = (page) => {
    setIsLoading(true)
    setData([])
    
    window.scrollTo(0, 0);
    setNewsPage(page);
  };
  const getData = (key = null) => {
    // console.log(key, "koenkoen");
    setDisplaySearch(null);
    let searchQuery = ``;
    let paginationQuery = `?limit=12&offset=${(newsPage - 1) * 12}`;
    if (key) {
      setDisplaySearch(key);
      searchQuery += `?news_title=${key}`;
      paginationQuery = `&limit=12&offset=${(newsPage - 1) * 12}`;
    }
    console.log(
      `${server}/api/v1/kisiNews/list${searchQuery}${paginationQuery}&active=1`
    );

    fetch(`${server}/api/v1/kisiNews/list${searchQuery}${paginationQuery}&active=1`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        // console.log("reset", data);
        if (data.status) {
          // console.log("reset", data);
          setIsLoading(false);
          setData(data.data.filter((d) => d.active == 1));
        }
        // console.log(data, "promox");
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const getDataByTag = (key = null) => {
    console.log(search, "diget");

    let uri = `${server}/api/v1/kisiNews/list?news_tag=["${request}"]&limit=12&offset=${
      (newsPage - 1) * 12
    }`;
    // if (key && request) {
    //   uri += `?news_tag=["${request}"]`;
    //   console.log(uri);
    // } else if (key && request) {
    // } else if (!key && search) {
    //   uri += `?news_title=${search}`;
    // }
    // console.log(uri);

    fetch(uri, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        console.log("reset", data);
        if (data.status) {
          console.log("baytag", data);
          setData(data.data.filter((d) => d.active == 1));
        }
        // console.log(data, "promox");
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const handleSearch = (e) => {
    navigate(`/blog/edukasi/list`);
    e.preventDefault();
    setNewsPage(1);
    getData(search);
  };
  return (
    <>
      {/* <div class="monsterratExtraBold mt-5 mb-4 mx-auto text-center f275r  blue-text-1 mw90768">
        List Capping Saham
      </div> */}
      <div
        class="subPathWrapper  pb-5"
        style={{
          paddingTop: "0rem",
        }}
      >
        {/* {JSON.stringify(data)} */}
        <div class="w65 w901000 w851300 mx-auto">
          <div className="w100 pb-3 px-3 mb-2">
            <form
              onSubmit={(e) => {
                handleSearch(e);
              }}
            >
              <input
                className="form-control rounded-pill px-3 monsterratMedium"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder="Search"
                value={search}
                style={{
                  borderColor: "#6E6F72",
                  backgroundImage: `url(${searchIcon})`,
                  backgroundSize: "25px",
                  backgroundRepeat: "no-repeat",
                  backgroundPositionX: "98%",
                  backgroundPositionY: "center",
                }}
                // type="search"
                // placeholder="Ketik Untuk mencari"
              ></input>
            </form>
          </div>
          <div class="px-4  monsterratMedium">
            {displaySearch && displaySearch.length > 0 ? (
              <div class="mb-3">
                <span class="grey">hasil pencarian :</span>&nbsp;{" "}
                {displaySearch}
              </div>
            ) : null}
            {isTag ? (
              <div class="mb-3">
                <span class="grey">berita dengan tag : {request}</span>&nbsp;{" "}
                {displaySearch}
              </div>
            ) : null}
          </div>
          <div
            class="flex capping-cont block1000 edukasiListContainer"
            style={{
              // gap:35,
              display: "flex",
              flexWrap: "wrap",
              flex: "0 0 33.3333%",
            }}
          >
            {data.length > 0 ? (
              <>
                {data.map((news, i) => {
                  return (
                    <div
                      class="edukasiThumbDiv w1001000 justify-content-between mb-1 px-4 pb-3"
                      style={{
                        lineHeight: 1.6,
                        // textAlign:'justify'
                      }}
                      onClick={(e) => {
                        const result = news.news_title.replace(/[?\/]/g, "");

                        navigate(`/blog/edukasi/${result}/${news.news_id}`);
                      }}
                    >
                      <div
                        style={{ fontSize: 18 }}
                        class=" monsterratBold blue-text-1 thumbTitle"
                        title={news.news_title}
                      >
                        {" "}
                        {news.news_title}
                      </div>{" "}
                      <div
                        style={{ fontSize: 12 }}
                        class=" monsterratRegular grey"
                      >
                        {news.news_source && news.news_source.length > 0 ? (
                          <>
                            By {news.news_source}
                            {" | "}
                          </>
                        ) : null}
                        {moment(news.news_date)
                          .locale("id")
                          .format("dddd, D MMMM YYYY")}
                      </div>
                      <div
                        style={{ fontSize: 16 }}
                        class=" monsterratMedium edukasiThumb"
                        dangerouslySetInnerHTML={{ __html: news.news_content }}
                      ></div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {displaySearch ? (
                  <div class="w100 my-5 py-5 text-center monsterratBold grey">
                    <h2>Berita tidak ditemukan</h2>
                  </div>
                ) : (
                  <>
                    {isTag ? (
                      <div class="w100 my-5 py-5 text-center monsterratBold grey">
                        <h2>Berita tidak ditemukan</h2>
                      </div>
                    ) : (
                      <div
                        className="w100 text-center center-vertical align-item-center"
                        style={{ height: 300 }}
                      >
                        {isLoading ? (
                          <img class="mx-auto" src={loading}></img>
                        ) : (
                          <div class="w100 my-5 py-5 text-center monsterratBold grey">
                            <h2>Berita tidak ditemukan</h2>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {newsPage == 1 ? (
            <div class="text-center mx-auto pt-5 pb-5">
              {/* <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                «
              </button> */}
              <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                ‹
              </button>
              <span class="mx-2"></span>
              <button class="numberButton numberButtonActive monsterratSemiBold f12r mx-2">
                1
              </button>
              {data.length < 12 ? (
                <>
                  <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                    2
                  </button>
                  <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                    3
                  </button>
                  <span class="mx-2"></span>
                  <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                    ›
                  </button>
                  <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                    »
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      newsPageHandle(2);
                    }}
                    class="numberButton monsterratSemiBold f12r mx-2"
                  >
                    2
                  </button>
                  <button
                    onClick={() => {
                      newsPageHandle(3);
                    }}
                    class="numberButton monsterratSemiBold f12r mx-2"
                  >
                    3
                  </button>
                  <span class="mx-2"></span>
                  <button
                    onClick={() => {
                      newsPageHandle(2);
                    }}
                    class="numberButton monsterratSemiBold f12r mx-2"
                  >
                    ›
                  </button>
                  {/* <button
                    onClick={() =>
                      newsPageHandle(Math.floor(data.length / 10 + 1))
                    }
                    class="numberButton monsterratSemiBold f12r mx-2"
                  >
                    »
                  </button> */}
                </>
              )}
            </div>
          ) : (
            <div class="text-center mx-auto pt-5">
              {/* <button
                onClick={() => {
                  newsPageHandle(1);
                }}
                class="numberButton monsterratSemiBold f12r mx-2"
              >
                «
              </button> */}
              <button
                onClick={() => {
                  newsPageHandle(newsPage - 1);
                }}
                class="numberButton monsterratSemiBold f12r mx-2"
              >
                ‹
              </button>
              <span class="mx-2"></span>
              <button
                onClick={() => {
                  newsPageHandle(newsPage - 1);
                }}
                class="numberButton  monsterratSemiBold f12r mx-2"
              >
                {newsPage - 1}
              </button>
              <button class="numberButton numberButtonActive monsterratSemiBold f12r mx-2">
                {newsPage}
              </button>

              {data.length < 12 ? (
                <>
                  <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                    {newsPage + 1}
                  </button>
                  <span class="mx-2"></span>
                  <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                    ›
                  </button>
                  {/* <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                    »
                  </button> */}
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      newsPageHandle(newsPage + 1);
                    }}
                    class="numberButton monsterratSemiBold f12r mx-2"
                  >
                    {newsPage + 1}
                  </button>
                  <span class="mx-2"></span>
                  <button
                    onClick={() => {
                      newsPageHandle(newsPage + 1);
                    }}
                    class="numberButton monsterratSemiBold f12r mx-2"
                  >
                    ›
                  </button>
                  {/* <button
                    onClick={() => {
                      newsPageHandle(Math.floor(data.length / 10 + 1));
                    }}
                    class="numberButton monsterratSemiBold f12r mx-2"
                  >
                    »
                  </button> */}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
