import "./style.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
export default function PageLocation(props) {
  const page = window.location.pathname;
  const [offset, setOffset] = useState(0);
  const params = useLocation();
  const currentPath = params.pathname;
  useEffect(() => {
  // console.log(page,currentPath);
  
    if (offset == 0 &&  document.getElementById("navBar")) {
      // if (window.innerWidth > 768) {
      //   document.getElementById("subPath").style.paddingTop = "5rem";
      // }
      // document.getElementById("subPath").style.boxShadow = "none";
      document.getElementById("navBar").style.boxShadow =
        "0px 4px 14px rgb(0 0 0 / 7%)";
      // document.getElementById("subPath").style.paddingBottom = "0rem";
    }
    if (isNoNavbar()) {
      // console.log('gasekuy');
      if (offset >= document.documentElement.offsetHeight - 1000) {
        document.getElementById("teleBtn").style.bottom = "130px";
        document.getElementById("waBtn").style.bottom = "195px";
      } else {
        document.getElementById("teleBtn").style.bottom = "15px";
        document.getElementById("waBtn").style.bottom = "80px";
      }
      const onScroll = () => setOffset(window.pageYOffset);
      // clean up code
      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
      return () => window.removeEventListener("scroll", onScroll);
    }
    //  console.log( document.documentElement.offsetHeight,'hilih');
  }, []);
  const navigate = useNavigate();
  const goTo = (route) => {
    // console.log("masuk");
    navigate(route);
  };
  const isNoNavbar = () => {
    if (
      currentPath !== "/koins/reset-password" &&currentPath !== "/koins/dev/reset-password" &&     currentPath !== "/koins/staging/reset-password" &&
      currentPath !== "/confirmation" &&
      currentPath.substring(0, 17) !== "/signature-spouse"
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      {isNoNavbar() ? (
        <>
          <div
            className="wa-contact tele-contact d-block "
            style={{
              bottom: 140,
            }}
            id="teleBtn"
          >
            <a href="https://t.me/TrueFriendKISI" target="_blank">
              <div className="center-vertical nowrap wa-contact-txt">
              Bergabung dengan Kami
              </div>
              <div className="wa-contact-icon tele-icon"></div>
            </a>
          </div>
          <div className="wa-contact d-block " id="waBtn">
            <a href="https://wa.me/628151881911" target="_blank">
              <div className="center-vertical nowrap wa-contact-txt">
              Bantuan Call Center 
              </div>
              <div className="wa-contact-icon"></div>
            </a>
          </div>
        </>
      ) : null}
    </>
  );
}
