import logo from "../../logo.svg";
import "./style.css";
import PageLocation from "../../components/PageLocation";
import indoMap from "../../assets/indoMap.png";
import waran2 from "../../assets/waran2.png";
import moment from "moment";
import "moment/locale/id";
import waran3 from "../../assets/waran3.png";
import waran4 from "../../assets/waran4.png";
import syariahSertifikatMui from "../../assets/syariahSertifikatMui.pdf";
import manual_order_e_ipo from "../../assets/manual_order_e_ipo.pdf";
import manualPendaftaranEIpo from "../../assets/manualPendaftaranEIpo.pdf";
import whatsapp from "../../assets/whatsapp.png";
import panduanImg from "../../assets/panduanImg.png";
import kisiMobile2 from "../../assets/kisiMobile2.png";
import letter from "../../assets/letter.png";
import arrow from "../../assets/arrow.png";
import "swiper/css/scrollbar";
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
import Swal from "sweetalert2";
import "swiper/css";

import "swiper/css/scrollbar";
import close from "../../assets/close.png";
import locPopup from "../../assets/locPopup.png";
import mail from "../../assets/email.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Scrollbar } from "swiper/modules";
import { storageLocation } from "../../config/server";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { setActiveMenu } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
import {} from "../../store/action";
import { server } from "../../config/server";
import YouTubePlaylist from "@codesweetly/react-youtube-playlist";
import playStore from "../../assets/icon-download-android.svg";
import appStore from "../../assets/icon-download-ios.svg";
import promo1 from "../../assets/promo1.jpeg";
import promo2 from "../../assets/promo2.jpeg";
import promo3 from "../../assets/promo3.jpeg";
import ngobrol1 from "../../assets/ngobrol1.jfif";
import ngobrol2 from "../../assets/ngobrol2.jfif";
import ngobrol3 from "../../assets/ngobrol3.jfif";
import ngobrol4 from "../../assets/ngobrol4.jfif";
import ngobrol5 from "../../assets/ngobrol5.jfif";
import withReactContent from "sweetalert2-react-content";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";

// import playStore from "../../assets/playStore.png";
// import appStore from "../../assets/appStore.png";
export function KantorCabang() {
  const { page } = useParams();
  const dispatch = useDispatch();
  const [branchList, setBranchList] = useState([]);
  const [branchIslandList, setBranchIslandList] = useState([]);
  const [selectedIsland, setSelectedIsland] = useState();
  const [swiper, setSwiper] = useState(<Swiper />);
  const [swiperEvent, setSwiperEvent] = useState(<Swiper />);
  const [eventList, setEventList] = useState([]);
  const [eventListEdukasi, setEventListEdukasi] = useState([]);
  const ytApikey = process.env.REACT_APP_YT_API_KEY;
  const [panduanList, setPanduanList] = useState([]);
  const MySwal = withReactContent(Swal);
  const [galleryList, setGalleryList] = useState([]);
  const [selectedGallery, setSelectedGallery] = useState([]);
  const [swiperGallery, setSwiperGallery] = useState(<Swiper />);
  const [index, setIndex] = useState(-1);
  const [index2,setIndex2]= useState(-1)
  const activeBranch = branchList.filter(
    (b, i) => b.branch_island_id == selectedIsland
  );
  useEffect(() => {
    // window.scrollTo(0, 0);
    // console.log(page.substring(1, page.length), page);
    // document.getElementById(page).className +=
    dispatch(setActiveMenu("tentangKami"));
    //   " subPathActive";
    getBranchIsland();
    getBranchList();
    getEvents();
    getEventsEdukasi();
    getGallery();
    // console.log("gas");
    let islandTab = document.getElementsByClassName("cabangTabButtonLoop");
    // console.log("asu", islandTab);
    // document.getElementById(`island_${selectedIsland}`).classList.add("cabangTabButtonActive");

    for (let i = 0; i < islandTab.length; i++) {
      const element = islandTab[i];
      // console.log(element.id);
      if (element.id == `island_${selectedIsland}`) {
        document.getElementById(`island_${selectedIsland}`).className +=
          " cabangTabButtonActive";
      } else {
        element.className =
          "pointer monsterratBold blue-text-1 mx-3 cabangTabButton px-3 py-2 cabangTabButtonLoop";
      }
    }
  }, [selectedIsland]);

  const pulau = [1, 1, 1, 1, 1, 1];
  const cabang = [0, 0, 0, 0, 0, 0, 0, 0, 0];
  const event = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  const navigate = useNavigate();
  // const getPanduan = (file) => {
  //   // const host = `https://www.kisi.co.id/storage/panduan/`

  //   var win = window.open(host + `/storage/panduan/` + file, "_blank");
  //   win.focus();
  // };
  const getBranchIsland = (file) => {
    fetch(server + `/api/v1/about/branchIsland`)
      .then((res) => res.json())
      .then((island) => {
        // console.log(island, "ay");
        if (!selectedIsland) {
          // console.log("kosong", island.data[0].branch_island_id);
          setSelectedIsland(island.data[0].branch_island_id);
        }
        setBranchIslandList(island.data);
        // openInNewTab(panduan)

        // resolve("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getEvents = (file) => {
    fetch(server + `/api/v1/event/list?education_event=1`)
      .then((res) => res.json())
      .then((island) => {
        // console.log(island);
        setEventList(island.data);
        // openInNewTab(panduan)

        // resolve("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getEventsEdukasi = (file) => {
    fetch(server + `/api/v1/event/list?education_event=0`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);

        for (let i = 0; i < data.data.length; i++) {
          const element = data.data[i];
          
         element.img_link = `${server}${storageLocation}${element.image_event_link}`
            element.src = `${server}${storageLocation}${element.image_event_link}`
               element.description = element.content_event
               element.title = element.title_event
          // element.splitted_images.push(temp_obj);

          
        }
        setEventListEdukasi(data.data);
        // openInNewTab(panduan)

        // resolve("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getGallery = (file) => {
    fetch(server + `/api/v1/about/branchGallery`)
      .then((res) => res.json())
      .then((res) => {
        // console.log(res, "galer");
        let data_gallery = res.data;
        for (let i = 0; i < data_gallery.length; i++) {
          const element = data_gallery[i];
          // console.log(element, "y");
          const temp = element.branch_gallery_images_link.split(",");
          // element.splitted_images = element.branch_gallery_images_link.split(',')
          element.formatted_gallery_name = "";
          element.branch_name = "";
          if (element.branch_company !== undefined) {
            element.branch_name = element.branch_company.branch_name;
            for (
              let x = 0;
              x < element.branch_company.branch_name.length;
              x++
            ) {
              const element3 = element.branch_company.branch_name[x];
              if (element3 !== ",") {
                element.formatted_gallery_name += element3;
              } else {
                element.formatted_gallery_name += "<br/>";
              }
            }
          }

          element.splitted_images = [];
          for (let j = 0; j < temp.length; j++) {
            const element_img = temp[j];

            let temp_obj = {
              img_link: element_img,
              img_desc: element.branch_gallery_link,
              src: `${server}${storageLocation}${element_img}`,
              description: element.branch_gallery_link,
              title: element.branch_name,
            };
            element.splitted_images.push(temp_obj);
          }
          // console.log(element, "yy");

          // console.log(element.splitted_images);
        }
        let data_gallery_merged = [];
        for (let i = 0; i < data_gallery.length; i++) {
          const element = data_gallery[i];
          // console.log(element,'gl');
          let flag = false;

          for (let j = 0; j < data_gallery_merged.length; j++) {
            const element_merged = data_gallery_merged[j];
            if (element_merged.branch_id == element.branch_id) {
              element_merged.splitted_images =
                element_merged.splitted_images.concat(element.splitted_images);
              flag = true;
              continue;
            }
          }
          // console.log('bendera');
          if (!flag) {
            // console.log(flag);
            data_gallery_merged.push(element);
            // console.log(data_gallery_merged,'kie');
          }
        }
        // console.log(data_gallery_merged, "gabong");
        setGalleryList(data_gallery_merged);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getBranchList = (file) => {
    fetch(server + `/api/v1/about/branch`)
      .then((res) => res.json())
      .then((list) => {
        // console.log(list);
        for (let i = 0; i < list.data.length; i++) {
          const element = list.data[i];
          // console.log(element);
          element.formatted_gallery_name = "";

          for (let j = 0; j < element.branch_name.length; j++) {
            const element2 = element.branch_name[j];
            if (element2 !== ",") {
              element.formatted_gallery_name += element2;
            } else {
              element.formatted_gallery_name += "<br/>";
            }
          }
        }

        setBranchList(list.data);
        // openInNewTab(panduan)

        // resolve("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const eventModal = (e) => {
    const eventModal = MySwal.mixin({
      // toast: true,
      allowOutsideClick: false,
      customClass: "event-popup",
      className: "event-popup",
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup:
          "animated fadeInDown faster event-popup center-vertical swalEvent",
        icon: "animated heartBeat delay-1s",
      },
      hideClass: {
        popup: "animated fadeOutUp faster",
      },
      showConfirmButton: false,
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger",
      },
      backdrop: true,
      allowOutsideClick: false,
      html: (
        <div class="eventModal px-5 flex py-5 text-center center-vertical">
          <div class=" eventModalImgDiv  py-1 px-3">
            <div
              class="none-desktop d1000"
              style={{ float: "right", textAlign: "right" }}
            >
              <img
                onClick={(e) => {
                  MySwal.close();
                }}
                style={{ height: 40, marginLeft: "auto" }}
                src={close}
              ></img>
            </div>
            <img
              class="loadingImgModal eventModalImg "
              src={`${server}${storageLocation}${e.image_event_link}`}
            />
          </div>
          <div class="eventPopupDetail w100 " style={{ paddingLeft: 30 }}>
            <div class="none-desktop d1000" style={{ height: 40 }}></div>
            <div
              class="none-1000"
              style={{ float: "right", textAlign: "right" }}
            >
              <img
                onClick={(e) => {
                  MySwal.close();
                }}
                style={{ height: 40, marginLeft: "auto" }}
                src={close}
              ></img>
            </div>
            <div style={{ textAlign: "left" }}>
              <div class="f2r blue-text-1 monsterratBold mb-3">
                {" "}
                {e.title_event}
              </div>
              <div class="monsterratMedium f1r grey mb-3 ">
                {" "}
                {e.content_event}
              </div>
              <div class="monsterratMedium f12r mb-3 ">
                {" "}
                {e.branch_company.branch_name}
              </div>
              <div class="monsterratMedium grey mb-3 f1r">
                {e.date_start == e.date_end ? (
                  <>
                    {moment(e.date_start)
                      .locale("id")
                      .format("dddd, DD MMMM yyyy")}
                  </>
                ) : (
                  <>
                    {" "}
                    {moment(e.date_start)
                      .locale("id")
                      .format("dddd, DD MMMM yyyy")}{" "}
                    -{" "}
                    {moment(e.date_end)
                      .locale("id")
                      .format("dddd, DD MMMM yyyy")}
                  </>
                )}
              </div>

              <div class="flex">
                <div style={{ marginRight: "1.2rem" }}>
                  <img src={locPopup} style={{ height: 33 }}></img>
                </div>
                <div class="monsterratMedium  f1r center-vertical">
                  {e.place}
                </div>
              </div>
            </div>
            <div style={{ textAlign: "right" }}>
              <button
                style={{
                  borderRadius: 12,
                  paddingTop: "0.66rem",
                  paddingBottom: "0.66rem",
                }}
                class="text-center monsterratSemiBold blue-text-1 f085r cabangTabButton px-4 "
                onClick={(x) => {
                  // e.preventDefault()
                  navigate(`/cabang/${e.branch_id}`);
                  MySwal.close();
                }}
              >
                Informasi <i>Sales</i>
              </button>
            </div>
          </div>
        </div>
      ),
      showConfirmButton: false,
      heightAuto: false,
    });
    eventModal.fire();
  };
  function detectKOINS() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"

    if (/android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=com.koins.mtsand&hl=in&gl=US";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "https://apps.apple.com/id/app/koins-mobile/id1436459041";
    }
    return "https://apps.apple.com/id/app/koins-mobile/id1436459041";
  }

  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  const productList = [
    {
      name: "Panduan Pembukaan Akun",
      id: "panduan_pembukaan_akun",
      path: "/panduan/pembukaan_akun",
    },
    {
      name: "Panduan Penggunaan Aplikasi Koins",
      id: "panduan_koins",
      path: "/panduan/koins",
    },
    {
      name: "Panduan KISI Mobile",
      id: "panduan_kisi_mobile",
      path: "/panduan/kisi_mobile",
    },
    {
      name: "Panduan Penggunaan Aplikasi Kinds",
      id: "panduan_kinds",
      path: "/panduan/kinds",
    },
    { name: "Panduan e-IPO", id: "panduan_e_ipo", path: "/panduan/e_ipo" },
  ];
  const eventArrOffline = [
    {
      isOffline: true,
      image: promo1,
      link_event: "https://www.instagram.com/reel/Ch64PjYpNl9/",
    },
    {
      isOffline: true,
      image: promo2,
      link_event: "https://www.instagram.com/reel/CjAZe84phEV/",
    },

    {
      isOffline: true,
      image: promo3,
      link_event: "https://www.instagram.com/reel/CjsgalnJMRc/",
    },

    {
      isOffline: true,
      image: ngobrol1,
      link_event: "https://www.instagram.com/reel/Cl09-t_JWns/",
    },

    {
      isOffline: true,
      image: ngobrol2,
      link_event: "https://www.instagram.com/reel/ClYUGHGpHG0/",
    },

    {
      isOffline: true,
      image: ngobrol3,
      link_event: "https://www.instagram.com/reel/CkxtCfJJfo1/",
    },

    {
      isOffline: true,
      image: ngobrol4,
      link_event: "https://www.instagram.com/reel/Cmbme89Pk3S/",
    },

    {
      isOffline: true,
      image: ngobrol5,
      link_event: "https://www.instagram.com/reel/CicXGXApov0/",
    },
  ];
  return (
    <>
      <div
        class="w70 mx-auto w90768 cabangMain"
        style={{
          marginTop: "12rem",
        }}
      >
        <div class="f25r monsterratExtraBold blue-text-1 text-center pt-2 pb-5 mb-3 ">
          INDONESIA
        </div>
        <img class="w100" src={indoMap}></img>
        <div class="f25r monsterratSemiBold blue-text-1 mt-4 mb-5">
          #WaktumuInvestasimu
        </div>
      </div>
    {
      eventListEdukasi.length > 0 ? <>  <div class="f25r monsterratExtraBold blue-text-1 text-center pt-5 ">
      Kegiatan Bulan Ini
    </div>
    <div class="w100 flex">
      <div class="center-vertical">
        <img
          onClick={(e) => {
            swiperEvent.slidePrev();
          }}
          class="sliderArrow arrowCabangL"
          style={{ transform: "rotate(270deg)", marginLeft: "3rem" }}
          src={arrow}
        ></img>
      </div>
      <div className="w85 mx-auto py-3 my-5 w75768" >
        {/* {JSON.stringify(eventListEdukasi)} */}
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={false}
          slidesPerView={4}
          // loop={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          breakpoints={{
            1300: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 1,
            },
            200: {
              slidesPerView: 1,
            },
          }}
          // speed={1200}
          // autoplay={{
          //   delay: 1800,
          //   pauseOnMouseEnter: true,
          //   disableOnInteraction: false,
          //   waitForTransition: true,
          //   stopOnLastSlide: false,
          // }}
          modules={[Autoplay]}
          pagination={true}
          // className="swiperNgobrol"
          // onTransitionEnd={(e) => ngobrolSlideChange(e)}
          // navigation={true}
          onSwiper={(swiper) => {
            // swiper.$wrapperEl[0].style.transitionTimingFunction = "linear";
            setSwiperEvent(swiper);
          }}
        >
          {eventListEdukasi.map((slide, i) => {
            return (
              <SwiperSlide key={i}>
                {/* acac{JSON.stringify(eventListEdukasi)} */}
                <div className="w100 text-center"
                 onClick={(e) => {
                  setIndex2(i);

                }}>
                  <img
                    className="slide-image-ngobrol slide-event-non-ed mx-auto"
                    src={`${server}${storageLocation}${slide.image_event_link}`}
                    alt=""
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Lightbox
            slides={eventListEdukasi}
            open={index2 >= 0}
            index={index2}
            close={() => {
              setIndex2(-1);
            }}
            // enable optional lightbox plugins
            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Captions]}
          />
      </div>
      <div class="center-vertical">
        <img
          class="sliderArrow arrowCabangR"
          onClick={(e) => {
            swiperEvent.slideNext();
          }}
          src={arrow}
          style={{
            transform: "rotate(90deg)",
            marginLeft: "auto",
            marginRight: "3rem",
          }}
        ></img>
      </div>
    </div></>:null
    }

      <div class=" pb-5">
        <div class="none-desktop">
          <select
            onChange={(e) => setSelectedIsland(e.target.value)}
            class="form-select subPathSelect monsterratMedium f12r"
            aria-label="Default select example"
            style={{
              position: "relative",
              marginBottom: "2rem",
            }}
          >
            {branchIslandList.map((branch, i) => {
              if (selectedIsland == branch.branch_island_id) {
                return (
                  <option
                    selected
                    class="subPathOption"
                    value={branch.branch_island_id}
                  >
                    {branch.branch_island_name}
                  </option>
                );
              } else {
                return (
                  <option class="subPathOption" value={branch.branch_island_id}>
                    {branch.branch_island_name}
                  </option>
                );
              }
            })}
          </select>
        </div>
        {eventListEdukasi.length > 0 ?   <div class="f25r monsterratExtraBold blue-text-1 text-center py-5">
          Informasi Kegiatan
        </div>:null}
     
        <div class="subPathContainer pb-5 appPageLocation none-mobile">
          {branchIslandList.length > 0 ? (
            <>
              {branchIslandList.map((p, i) => {
                return (
                  <>
                    {selectedIsland == p.branch_island_id ? (
                      <div
                        className=" pointer monsterratBold blue-text-1 mx-3 cabangTabButton px-3 py-2 cabangTabButtonLoop cabangTabButtonActive"
                        id={`island_${p.branch_island_id}`}
                        onClick={() => setSelectedIsland(p.branch_island_id)}
                      >
                        {p.branch_island_name}
                      </div>
                    ) : (
                      <div
                        className=" pointer monsterratBold blue-text-1 mx-3 cabangTabButton px-3 py-2 cabangTabButtonLoop"
                        id={`island_${p.branch_island_id}`}
                        onClick={() => setSelectedIsland(p.branch_island_id)}
                      >
                        {p.branch_island_name}
                      </div>
                    )}
                  </>
                );
              })}
            </>
          ) : null}
        </div>
        <div class="w85 mx-auto mb-5">
          
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={false}
            slidesPerView={5}
            // loop={true}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            breakpoints={{
              1650: {
                slidesPerView: 4,
              },
              15000: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
              },
              200: {
                slidesPerView: 1,
              },
            }}
            scrollbar={{ draggable: true, el: ".swiper-scrollbarx" }}
            // speed={1200}
            //     autoplay={{

            // delay:1200,
            //       pauseOnMouseEnter: true,
            //       disableOnInteraction: false,
            //       waitForTransition: true,
            //       stopOnLastSlide: false,
            //     }}
            modules={[Autoplay, Scrollbar]}
            pagination={true}
            className="swiperNgobrol"
            // onTransitionEnd={(e) => ngobrolSlideChange(e)}
            // navigation={true}

            onSwiper={(swiper) => {
              // swiper.$wrapperEl[0].style.transitionTimingFunction = "linear";
              setSwiper(swiper);
            }}
          >
            {/* {JSON.stringify(selectedIsland)} */}
            {/* {JSON.stringify(branchList)} */}

            {eventList
              .filter((b, i) => {
                return b.branch_company.branch_island_id == selectedIsland;
              })
              .map((b, i) => {
                return (
                  <SwiperSlide key={i} onClick={(e) => eventModal(b)}>df
                    <div class="eventCard pointer mx-auto">
                      <div
                        class="eventPulauImg w100"
                        style={{
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "50% 0%",
                          backgroundImage: `url(${server}${storageLocation}${b.image_event_link})`,
                        }}
                      ></div>
                      <div
                        class="px-3 pt-3"
                        style={{
                          textAlign: "left",
                          height: "45%",
                          // display: "flex",
                          // flexDirection: "column",
                          // justifyContent: "space-between",
                        }}
                      >
                        <div
                          class="f12r blue-text-1 monsterratBold mb-2"
                          style={{
                            minHeight: 75,
                          }}
                        >
                          {b.title_event}
                        </div>
                        <div class="monsterratMedium grey mb-2 f1r">
                          {b.date_start == b.date_end ? (
                            <>
                              {moment(b.date_start)
                                .locale("id")
                                .format("dddd, DD MMMM yyyy")}
                            </>
                          ) : (
                            <>
                              {" "}
                              {moment(b.date_start)
                                .locale("id")
                                .format("dddd, DD MMMM yyyy")}{" "}
                              -{" "}
                              {moment(b.date_end)
                                .locale("id")
                                .format("dddd, DD MMMM yyyy")}
                            </>
                          )}
                        </div>
                        <div
                          style={{ minHeight: 40 }}
                          class="monsterratBold f12r  pt-2 pb-4"
                        >
                          {b.branch_company.branch_name}
                        </div>
                        {/* <div class="flex">
                          <div class="monsterratMedium grey f1r">
                            Rukan Boulevard Arta Gading (Gading Kirana) Blok A7C
                            no.7, Kelapa Gading, Jakarta Utara, 14240
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}

            {/* <SwiperSlide key={'x'} onClick={(e) => eventModal.fire()}>
                  <div class="eventCard">
                    <div
                      class="eventPulauImg w100"
                      style={{
                        background: `url(${ngobrol3})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                    <div class="px-3 pt-3" style={{ textAlign: "left" }}>
                      <div class="f12r blue-text-1 monsterratBold mb-2">
                        NAMA EVENT
                      </div>
                      <div class="monsterratMedium grey mb-2 f1r">
                        3 des 2022
                      </div>
                      <div class="monsterratBold f1r mb-2 ">
                        Kelapa Gading, Jakarta
                      </div>
                      <div class="flex">
            
                        <div class="monsterratMedium grey f1r">
                          Rukan Boulevard Arta Gading (Gading Kirana) Blok A7C
                          no.7, Kelapa Gading, Jakarta Utara, 14240
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide> */}
          </Swiper>
          <div class="swiper-scrollbarx w100"></div>
        </div>
        <div class="f25r monsterratExtraBold blue-text-1 text-center py-5">
          Dokumentasi Kegiatan
        </div>

        <div class="w85 mx-auto mb-5">
          
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            // centeredSlides={true}
            slidesPerView={5}
            // loop={true}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            spaceBetween={20}
            breakpoints={{
              1650: {
                slidesPerView: 5,
              },
              15000: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
              },
              200: {
                slidesPerView: 1,
              },
            }}
            // scrollbar={{ draggable: true, el: ".swiper-scrollbarx" }}
            // speed={1200}
            //     autoplay={{

            // delay:1200,
            //       pauseOnMouseEnter: true,
            //       disableOnInteraction: false,
            //       waitForTransition: true,
            //       stopOnLastSlide: false,
            //     }}
            modules={[Autoplay]}
            pagination={true}
            className="swiperNgobrol"
            // onTransitionEnd={(e) => ngobrolSlideChange(e)}
            // navigation={true}

            onSwiper={(swiper) => {
              // swiper.$wrapperEl[0].style.transitionTimingFunction = "linear";
              setSwiperGallery(swiper);
            }}
          >
            {/* {JSON.stringify(selectedIsland)} */}
            {/* {JSON.stringify(branchList)} */}

            {galleryList.map((g, i) => {
              return (
                <SwiperSlide
                  key={i}
                  onClick={(e) => {
                    setIndex(0);
                    setSelectedGallery(g.splitted_images);
                  }}
                >
                  {/* {index} */}

                  {/* {JSON.stringify(`${g.splitted_images[0].src}`)} */}
                  <div
                    class="galleryThumbnail pointer mx-auto"
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "50% 0%",
                      backgroundImage: `url(${g.splitted_images[0].src})`,
                    }}
                  ></div>
                  {g.branch_company ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${g.formatted_gallery_name}`,
                      }}
                      class="f12r mt-3 monsterratMedium"
                    ></div>
                  ) : (
                    <div class="f12r mt-3 monsterratMedium"></div>
                  )}
                </SwiperSlide>
              );
            })}
            <Lightbox
              slides={selectedGallery}
              open={index >= 0}
              index={index}
              close={() => {
                setIndex(-1);
              }}
              // enable optional lightbox plugins
              plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Captions]}
            />
          </Swiper>
        </div>
        <div class="w85 mx-auto">
          <div class="f25r monsterratExtraBold blue-text-1 text-center py-5 mb-3">
            Kantor Cabang / Galeri
          </div>
          <div class="cabangWrapper">
            {branchList
              .filter(
                (b, i) => b.branch_island.branch_island_id == selectedIsland
              )
              .map((b, i) => {
                return (
                  <div class="alamatCabang mt-5 mx-auto pt-3 pb-4 px-4">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${b.formatted_gallery_name}`,
                      }}
                      class="alamatTitle f2r monsterratBold f2r blue-text-1 text-center"
                    ></div>
                    <div class="grey my-2 addressDetails cabangDetail f1r monsterratMedium fw-500">
                      <div class="grey pinContainer">
                        <img class="locationImg" src={locPopup} />
                      </div>
                      <div>{b.branch_address}</div>
                    </div>
                    <div class="w100 text-center">
                      {" "}
                      <button
                        style={{
                          borderRadius: 12,
                          paddingTop: "0.66rem",
                          paddingBottom: "0.66rem",
                          width: "70%",
                          minWidth: "fit-content",
                        }}
                        class="text-center monsterratSemiBold blue-text-1 f085r cabangTabButton px-4 "
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/cabang/${b.branch_id}`);
                        }}
                      >
                        Informasi <i>Sales</i>
                      </button>
                    </div>
                  </div>
                );
              })}

            {/* {lokasi == "indonesia" ? (
            <div class="alamat mt-4 mx-3 pt-3 pb-4 px-4 center-vertical text-center">
              <div class="alamatTitle monsterratBlack f2r blue-text-1 comingAddress ">
                Coming Soon
              </div>
            </div>
          ) : null} */}
          </div>
        </div>

        {/* <div class="flex eventCabang">
       
          {event.map((e, i) => {
            return <div class="eventCard"></div>;
          })}
        </div> */}
      </div>
    </>
  );
}
