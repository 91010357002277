import "./style.css";
import React, { useEffect, useState } from "react";
import PageLocation from "../../../components/PageLocation";
import etfFee from "../../../assets/etfFee.png";
import playStore from "../../../assets/playStore.png";
import appStore from "../../../assets/appStore.png";
import etf from "../../../assets/etf.jpg";
import FollowSocmed from "../../../components/FollowSocmed";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import koins from "../../../assets/koins.png";
import kisiMobile from "../../../assets/kisiMobile.png";
import letter from "../../../assets/letter.png";
import mail from "../../../assets/email.png";
export function Etf() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const [imageLoaded, setImageLoaded] = useState(false);
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US";
    }

    return "https://apps.apple.com/id/app/kisi-mobile/id1493151464";
  };
  return (
    <div className={`${imageLoaded ? "o1" : "o0"}`}>
      {/* <PageLocation left="Home" right="ETF" subPath="Etf" /> */}
      {/* <div class="etfWrapper">
        {" "}
        <div class="etfContainer">
          <div class="etfTextContainer ">
            <div class="etfTextWrapper">
            <div class="monsterratBlack grey kisiMobileBigText blue-text-1">
              ETF BIKIN HAPPY
            </div>
            <div
              class="monsterratBlack grey kisiMobileBigText blue-text-1"
              style={{ marginTop: "-1rem" }}
            >
              1 LOT / ETF
            </div>
            <div class="monsterratMedium grey kisiMobileSmallText">
              <p>Reksadana Dana Indeks KISI MSCI INDONESIA</p>
            </div>
            <div
              class="monsterratMedium grey kisiMobileSmallText"
              style={{ marginTop: "-0.5rem" }}
            >
              Untuk Pendaftar Baru
            </div>
            <div class="downloadButtonGroup">
              <div class=" etfDownloadButtonContainer">
                <button
                  class="blue-btn rounded-pill monsterratBold downloadButton koinsDownload px-4"
                  onClick={() => openInNewTab(getMobileOperatingSystem())}
                >
                  Pendaftaran Online
                </button>
                <div class="flex mobileMarketplaceContainer">
                  <div class="  flexEnd appStore">
                    <img
                      class="mobileMarketplace"
                      src={appStore}
                      onClick={() =>
                        openInNewTab(
                          "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                        )
                      }
                    />

                  
                  </div>
                  <div class="  flexStart playStore">
                      <img
                        class="mobileMarketplace"
                        src={playStore}
                        onClick={() =>
                          openInNewTab(
                            "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                          )
                        }
                      />
                    </div>
                </div>
              </div>
              <div class="monsterratRegular grey disclaimerText mt-4">
                *Selain Nasabah Bank BCA & CIMB Niaga, Hubungi Customer Service
                Kami
              </div>
            </div>
          </div>
            </div>
           
          <div class="etfImgContainer">
            <img class="rocketImg" src={etf} />
          </div>
        </div>
      </div> */}

      <div class="syariahPageContainer">
        <div class="syariahPageWrapper">
          {/* <div class="syariahImgContainer">
            <img class="syariahImg" src={koinsSyariah}></img>
          </div> */}
          <div class="etfContainer productBanner">
            <div class="etfTextContainer ">
              <div class="etfTextWrapper">
                <div class="monsterratBlack f4r bannerTitle grey  nowrap blue-text-1">
                 Exchange<br></br>Traded<br></br>Fund (ETF)<br class="brMobile"></br>
                </div>
                {/* <div
                  class="monsterratBlack f4r bannerTitle grey  blue-text-1"
               
                >
                  1 LOT / ETF
                </div> */}
                <div class="monsterratMedium bannerSubtitle  mt-4 grey kisiMobileSmallText f12r">
                PT Korea Investment And Sekuritas Indonesia<br></br> sebagai Dealer Partisipan

                </div>
          
                <div class="downloadButtonGroup etfDownloadGroup">
                  <div class=" etfDownloadButtonContainer">
                    <button
                      class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
                      onClick={() => navigate("/kisimobile")}
                    >
                      DOWNLOAD SEKARANG
                    </button>

                    <div class="flex mobileMarketplaceContainer">
                      <div class="  flexEnd appStore">
                        <img
                          class="mobileMarketplace"
                          src={appStore}
                          onClick={() =>
                            openInNewTab(
                              "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                            )
                          }
                        />
                      </div>
                      <div class="  flexStart playStore">
                        <img
                          class="mobileMarketplace"
                          src={playStore}
                          onClick={() =>
                            openInNewTab(
                              "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="monsterratRegular grey disclaimerText mt-5">
                    *Selain Nasabah Bank BCA & CIMB Niaga, Hubungi Customer
                    Service Kami
                  </div>
                </div>
              </div>
            </div>

            <div class="etfImgContainer">
              <img
                class="etfImg"
                src={etf}
                onLoad={() => {
                  setImageLoaded(true);
                }}
                data-aos="fade-up"
                data-aos-duration="3000"
              />
            </div>
          </div>
          <div class="syariahTextWrapper">
            <div class="f25r  mb-4 center768 blue-text-1 monsterratExtraBold">
              Definisi ETF
            </div>
            <div class="monsterratMedium  f1r center768">
              Reksa Dana ETF adalah Reksa Dana yang kinerjanya mengacu pada
              indeks tertentu dan diperjualbelikan layaknya seperti saham di
              bursa yang dapat dicermati pergerakannya.<br></br>
              <br></br> ETF ditujukan untuk memperoleh hasil investasi
              selayaknya bahkan outperform market return. Oleh karena itu, yang
              menjadi acuan dari produk ini adalah market indeks.<br></br>
              <br></br> Terkait manfaat, risiko, dan kewajiban relatif sama
              dengan produk atau jenis Reksa Dana lainnya. <br></br>
              <br></br>Perbedaannya adalah bahwa Reksa Dana ETF dapat dibeli
              melalui Perusahaan Efek (Broker) yang terdaftar di Bursa Efek dan
              bukan dari Agen Penjual Efek Reksa Dana (APERD).
            </div>
          </div>
          <div class="syariahTextWrapper mt-5 ">
            <div class="f25r  mb-4 blue-text-1 center768 monsterratExtraBold">
              Apa Perbedaan<br class="brMobile"></br> ETF & Reksa Dana
            </div>
            <div class="monsterratMedium f1r">
              <ul>
                <li>
                  Investasi <b>ETF</b> merupakan investasi berbentuk kontrak
                  kolektif. Unit pernyataannya akan diperdagangkan dan dicatat
                  dalam bursa saham Indonesia. Sedangkan Reksa Dana yang berisi
                  portofolio masing-masing saham, investasi <b>ETF</b> ini
                  berbentuk indeks, namun tetap dikelola oleh manajer investasi.{" "}
                  <br></br>
                </li>
                <br></br>
                <li>
                  Investor dapat memperoleh reksa dana melalui manajer investasi
                  atau agen penjualan reksa dana <i>online</i> dan <i>offline</i>. Tetapi{" "}
                  <b>ETF</b> tak dapat diakses melalui agen penjualan sama
                  sekali. Kita dapat mengakses melalui dealer partisipan dan
                  broker saham yang memfasilitasi perdagangan <b>ETF</b>.{" "}
                  <br></br>
                </li>
                <br></br>

                <li>
                  Reksa dana tidak dapat dijual kembali kepada pihak lain,
                  sedangkan <b>ETF</b> dapat diperjualbelikan layaknya saham
                  selama jam buka pasar modal. <br></br>
                </li>
                <br></br>
                <li>
                  Harga-harga pada reksa dana ditentukan berdasarkan harga pada
                  penutupan akhir hari, sedangkan harga <b>ETF</b> berubah
                  secara <i>real-time</i> mengikuti perubahan pasar secara
                  aktual. <br></br>
                </li>
                <br></br>
                <li>
                  <b>
                    <i>Underlying</i>
                  </b>{" "}
                  untuk reksa dana adalah saham, sementara untuk <b>ETF</b>{" "}
                  adalah Indeks Acuan <br></br>
                </li>
                <br></br>

                <li>
                  Minimum pembelian reksa dana 1 <i>unit</i>, sementara kalau beli{" "}
                  <b>ETF</b> di <b>pasar primer:</b> <i>creation <i>unit</i></i> ( = 1000 lot
                  = 100.000 <i>unit</i>) dan <b>pasar sekunder:</b> 1 Lot (100 <i>unit</i>){" "}
                  <br></br>
                </li>
                <br></br>
              </ul>
            </div>
            <button
              class=" mx-auto monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
              onClick={() => navigate("/blog/promo/promo_etf")}
            >
              SYARAT DAN KETENTUAN PROGRAM FREE GIFT 1 LOT ETF XKMS
            </button>
          </div>
        </div>
        {/* <div class="etfContent monsterratRegular">
          <p>
            <b>Syarat dan Ketentuan:</b>
          </p>
          <ol>
            <li>
              {" "}
              KISI akan memberikan ETF XKMS sebanyak 1 (satu) lot secara gratis
              kepada setiap Nasabah individu (retail) yang melakukan pembukaan
              rekening efek untuk pertama kalinya di KISI.
            </li>{" "}
            <li>
              {" "}
              Promo ini hanya berlaku bagi Nasabah yang telah melakukan
              registrasi pada periode Januari 2023 sampai dengan Desember 2023.
            </li>{" "}
            <li>
              {" "}
              Dalam Proses Registrasi, Calon Nasabah diharapkan untuk dapat
              menyertakan Identitas Diri, seperti: KTP dan NPWP.
            </li>{" "}
            <li>
              {" "}
              Bersedia melalukan Verifikasi Data Nasabah, melalui proses Video
              Call yang jadwal nya dapat disesuaikan oleh Calon Nasabah.
            </li>{" "}
            <li> Fee yang berlaku saat ini :</li>{" "}
            <div>
              <img src={etfFee} />
            </div>
            <li>
              {" "}
              Untuk bertransaksi atau menikmati fasilitas ETF silahkan mendaftar
              terlebih dahulu melalui KISI Mobile yang dapat di download dibawah
              ini
            </li>{" "}
            <div class="flex">
              <div>
                <img class="downloadImg mb-2" src={playStore}  onClick={() =>
                            openInNewTab(
                              "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                            )}/>
              </div>
              <div>
                <img  onClick={() =>
                            openInNewTab(
                              "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                            )
                          }class="downloadImg mb-2" src={appStore} />
              </div>
            </div>
            <li>
              {" "}
              Setelah berhasil melakukan pendaftaran dan melakukan deposit di
              bulan yang sama. Hanya dengan melakukan minimal deposit IDR
              100,000 nasabah akan mendapatkan Promo Free 1 Lot ETF yang akan di
              distribusikan pada bulan berikutnya.{" "}
            </li>{" "}
            <li>
              {" "}
              Promo hanya berlaku bagi Nasabah{" "}
              <b>
                yang telah melakukan pembukaan rekening efek dan penyetoran awal
                di bulan yang sama
              </b>
              , serta telah{" "}
              <b>
                melakukan konfirmasi dengan mengisi dan menyerahkan surat
                pernyataan serta form nego kepada KISI sebelum tanggal 10 pada
                bulan berikutnya.
              </b>
              <p>
                Surat Pernyataan dan Form Nego akan dikirimkan dari email
                hello@kisi.co.id ke email nasabah selambat-lambatnya setiap
                tanggal 5 dan setiap bulannya. Nasabah pun diharapkan dapat
                mengembalikan surat penyataan dan form nego yang masing-masing
                telah di tanda tangani dan dikembalikan melalui email ke
                hello@kisi.co.id , sebelum tanggal 10 pada bulan tersebut.{" "}
              </p>
              <br></br>
              <p>
                PT Korea Investment & Sekuritas Indonesia memohon maaf, jika
                tidak bisa memproses hadiah tersebut jika Nasabah mengembalikan
                Surat Pernyataan dan Form Nego setelah tanggal 10.
              </p>
            </li>{" "}
            <li>
              KISI akan melakukan pembelian ETF XKMS sebanyak 1 lot di rentang{" "}
              <b>waktu tanggal 11 sampai dengan tanggal 18 </b>pada bulan yang
              sama{" "}
              <b>
                setelah KISI menerima konfirmasi dari Nasabah serta telah
                dipenuhinya syarat dan ketentuan sebagaimana disebutkan pada
                pasal 1, 2 dan 3.
              </b>
            </li>
            <li>
              Biaya yang timbul sebagai akibat dari proses pemindahan ETF XKMS
              dari KISI kepada Nasabah sepenuhnya ditanggung oleh KISI. Setelah
              pemindahan selesai maka biaya transaksi ETF XKMS yang akan timbul
              di kemudian hari sepenuhnya ditanggung oleh Nasabah.
            </li>
          </ol>

          <p></p>
         
        </div> */}
      </div>

      {/* <div class="langkahPendaftaranWrapper rounded-corner bg-biru-muda py-4 my-4">
        <div class="f25r nowrap monsterratExtraBold langkahPendaftaranTitle blue-text-1">
          3 Langkah Mudah Pendaftaran <br></br>Menggunakan KISI Mobile
        </div>
        <div class="langkahPendaftaranContainer mb-5">
          <div class="langkahPendaftaran">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 1
            </div>
            <div class="langkahPendaftaranImgContainer">
              <img class="langkahPendaftaranImg " src={kisiMobile} />
            </div>
            <div class="langkahPendaftaranText grey monsterratMedium">
              Download KISI Mobile
            </div>
          </div>
          <div class="langkahSpacer monsterratBlack">- - - - - -</div>
          <div class="langkahPendaftaran  ">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 2
            </div>
            <div class="langkahPendaftaranImgContainer resume">
              <img class="langkahPendaftaranImg resume" src={letter} />
            </div>
            <div class="langkahPendaftaranText grey monsterratMedium">
              Ikuti instruksi untuk mendaftar
            </div>
          </div>
          <div class="langkahSpacer monsterratBlack">- - - - - -</div>
          <div class="langkahPendaftaran ">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 3
            </div>
            <div class="langkahPendaftaranImgContainer mail ">
              <img class="langkahPendaftaranImg " src={mail} />
            </div>
            <div
              style={{ width: "200%", marginLeft: "-50%" }}
              class="langkahPendaftaranText grey monsterratMedium"
            >
              Setelah berhasil, maka ID, PIM, & PASS KOINS akan dikirimkan
              melalui email yang didaftarkan
            </div>
          </div>
          <div class="langkahSpacer monsterratBlack">- - - - - -</div>
          <div class="langkahPendaftaran">
            <div class="monsterratBlack textCenter stepText blue-text-1 grey">
              Step 4
            </div>
            <div class="langkahPendaftaranImgContainer">
              <img class="langkahPendaftaranImg " src={koins} />
            </div>
            <div class="langkahPendaftaranText grey monsterratMedium">
              Download KOINS untuk melakukan Trading
            </div>
          </div>
        </div>
      </div> */}

      {/* <FollowSocmed /> */}
    </div>
  );
}
