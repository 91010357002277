import "./style.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenu, setArticlePage, setRnaMenu } from "../../store/action";
export default function BlogMenu(props) {
  // const { page } = useParams();
  const location = useLocation();
  const page = location.pathname.split("/")[2];
  const [offset, setOffset] = useState(0);
  const rnaMenu = useSelector((state) => state.rnaMenu);
  const dispatch = useDispatch();
  // const [rnaMenu,setRnaMenu] =  useState('daily')
  useEffect(() => {
    // console.log(location.pathname.split("/")[2]);

    // if (page !== undefined && page !== "") {
    // console.log(document.getElementsByClassName("subPath"));
    //   let test;
    //   // console.log(document.getElementById(page), test);
    //   test = document.getElementById(page);
    //   document.getElementById(page).className += " subPathActive";
    // }
    if (
      page == "artikel" ||
      page == "rna" ||
      page == "promo" ||
      page == "edukasi"
    ) {
      let allSubPath = document.getElementsByClassName("subpathx");
      for (let i = 0; i < allSubPath.length; i++) {
        const element = allSubPath[i];
        // console.log(element.className);
        element.classList.remove("subPathActive");
      }
      // document.getElementsByClassName("subPath")
      document.getElementById(page).className += " subPathActive";
    }

    if (page == "rna") {
      // console.log(document.getElementsByClassName(""));
      // let allSubPath = document.getElementsByClassName("rnaSubPath");
      // for (let i = 0; i < allSubPath.length; i++) {
      //   const element = allSubPath[i];
      //   // console.log(element.className);
      //   element.className = "subPath monsterratMedium rounded-pill mx-4 ";
      // }
      // document.getElementById(rnaMenu).className += " subPathActive";
    }
    // console.log(        document.getElementById("rnaSubPath"));
    // document.getElementById('syariah').className =
    //   "subPath monsterratMedium rounded-pill ";
    if (offset > 0) {
      document.getElementById("subPath").style.paddingTop = "0rem";
      document.getElementById("subPath").style.paddingBottom = "1rem";
      document.getElementById("navBar").style.boxShadow = "none";
      document.getElementById("subPath").style.boxShadow =
        "0px 4px 14px rgb(0 0 0 / 7%)";
      // document.getElementById("rnaPath").style.paddingTop = "1.6rem";
      // if(document.getElementById("rnaSubPath")        ){
      //   document.getElementById("rnaSubPath").style.paddingTop = "1.5rem";
      // }
      // document.getElementById('newsDetail').style.marginTop="100px"
    }
    if (offset == 0) {
      if (window.innerWidth > 768) {
        document.getElementById("subPath").style.paddingTop = "5rem";
        // document.getElementById('newsDetail').style.marginTop="100px"
      }
      document.getElementById("subPath").style.boxShadow = "none";
      document.getElementById("navBar").style.boxShadow =
        "0px 4px 14px rgb(0 0 0 / 7%)";
      document.getElementById("subPath").style.paddingBottom = "0rem";
      if (document.getElementById("rnaPath")) {
        document.getElementById("rnaPath").style.paddingTop = "0rem";
      }

      // document.getElementById('blogSpacer').style.height="60px"
      if (document.getElementById("rnaSubPath")) {
        document.getElementById("rnaSubPath").style.paddingTop = "0rem";
      }
    }
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [page, offset, rnaMenu]);
  // console.log(offset);
  const navigate = useNavigate();
  const productList = [
    { name: "Promo", id: "saham", path: "/blog/promo" },
    { name: "Berita Pasar", id: "artikel", path: "/blog/artikel" },
    { name: "Riset dan Analisis", id: "rna", path: "/blog/rna" },
    { name: "Edukasi", id: "edukasi", path: "/blog/edukasi/list" },
  ];
  return (
    <div>
      <div class="pageLocationWrapper ">
        {props.leftPath ? (
          <div class="path monsterratMedium mt-3 px-4 mx-5">
            <div
              class="leftSide"
              onClick={() => {
                navigate(props.leftPath);
              }}
            >
              {props.left}
            </div>
            <div class="divider" style={{ fontWeight: "900" }}>
              &nbsp;&nbsp;|&nbsp;&nbsp;
            </div>
            <div class="rightSide">{props.right}</div>
          </div>
        ) : null}
      </div>
      <div id="subPath" class="subPathWrapper  fixed-top none-mobile none-1000">
        <div class="subPathContainer blogSubPath justify-content-center">
          <div
            class="subPath mx-4 monsterratMedium rounded-pill subpathx"
            id="promo"
            onClick={() => navigate("/blog/promo")}
          >
            Promo
          </div>
          {props.from == "artikelById" ? (
            <div
              class="subPath mx-4 monsterratMedium rounded-pill subpathx "
              id="artikel"
              onClick={() =>
                navigate("/blog/artikel", { state: { from: "artikelById" } })
              }
            >
              Berita Pasar
            </div>
          ) : (
            <div
              class="subPath mx-4 monsterratMedium rounded-pill subpathx "
              id="artikel"
              onClick={() => navigate("/blog/artikel")}
            >
              Berita Pasar
            </div>
          )}
          <div
            class="subPath mx-2 monsterratMedium rounded-pill subpathx "
            id="rna"
            onClick={() => navigate("/blog/rna")}
          >
            Riset dan Analisis
          </div>
          <div
            class="subPath mx-2 monsterratMedium rounded-pill subpathx "
            id="edukasi"
            onClick={() => navigate("/blog/edukasi/list")}
          >
            Edukasi
          </div>
        </div>
      </div>
      <div id="blogSpacer" className="subPathSpacer"></div>
      <div class="none-desktop block1000">
        <select
          onChange={(e) => console.log(navigate(e.target.value))}
          className="form-select subPathSelect monsterratMedium f12r"
          aria-label="Default select example"
        >
          {productList.map((product, i) => {
            if (page == product.id) {
              return (
                <option selected className="subPathOption" value={product.path}>
                  {product.name}
                </option>
              );
            } else {
              return (
                <option className="subPathOption" value={product.path}>
                  {product.name}
                </option>
              );
            }
          })}
        </select>
      </div>
    </div>
  );
}
